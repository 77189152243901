import { GetServerSideProps, Redirect } from 'next'

import { CheckoutMain } from 'components'
import { NextPageWithLayout } from 'shared/interfaces/ILayout'
import {
  QueryClient,
  dehydrate,
  filterCookies,
  getNewCart,
  getCheckoutV2,
  getContentfulData,
  getCookiesString,
  DehydratedState,
  getCardsV2
} from '@smu-chile/pkg-unimarc-hooks'
import { CONTENTFUL_IDS, CONTENTFUL_TYPES } from 'shared/constants'
import { ICustomGetServerSidePropsResult } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICustomGetServerSidePropsResult'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'

interface ICheckoutPageSSRprops {
  dehydratedState?: DehydratedState
}

const CheckoutPage: NextPageWithLayout = () => {
  return <CheckoutMain />
}

export const getServerSideProps: GetServerSideProps = async (
  context
): Promise<
  ICustomGetServerSidePropsResult<ICheckoutPageSSRprops, Redirect>
> => {
  const queryClient = new QueryClient()
  const getCookies = filterCookies(context.req.cookies)
  const headers = { Cookie: getCookies }
  const requestOptions = { headers }
  const orderformId = context?.req?.cookies?.co_orderform_id || ''
  const saleschannel = context?.req?.cookies?.co_sc || ''
  const idToken = context?.req?.cookies?.co_idtoken || ''
  const caToken = context?.req?.cookies?.co_idtoken || ''

  // get contentful data
  await Promise.all([
    queryClient.prefetchQuery(['contentful_', 'terms-and-conditions'], () => {
      return getContentfulData({
        options: {
          'sys.id': CONTENTFUL_IDS.TERMS_AND_CONDITIONS
        }
      })
    }),
    queryClient.prefetchQuery(['contentful_', 'privacy-policy'], () => {
      return getContentfulData({
        options: {
          'sys.id': CONTENTFUL_IDS.PRIVACY_POLICY
        }
      })
    }),
    queryClient.prefetchQuery('checkoutConfig', () => {
      const getCookieValue = getCookiesString(context.req.cookies, [
        'checkoutConfig'
      ])
        ?.split('=')?.[1]
        ?.replace(/;$/g, '')

      const parseValue = JSON.parse(getCookieValue || '{}')
      return { ...parseValue, orderformId, saleschannel, idToken, caToken }
    }),
    queryClient.prefetchQuery(['contentful_', 'checkout_config'], () => {
      return getContentfulData({
        options: {
          'sys.id': CONTENTFUL_IDS.CHECKOUT_CONFIG
        }
      })
    }),
    queryClient.prefetchQuery(
      ['contentful_configuracionesGenerales', 'general_config'],
      () => {
        return getContentfulData({
          options: {
            content_type: CONTENTFUL_TYPES.GENERAL_CONFIG
          }
        })
      }
    ),
    queryClient.prefetchQuery(['contentful_unimarcWebTopFooter'], () => {
      return getContentfulData({
        id_contentful: 'contentful_minifooter',
        options: {
          content_type: 'unimarcWebTopFooter',
          'fields.label[in]': 'Mini Footer (Desktop)',
          'fields.platform': 'Web Unimarc eComm'
        }
      })
    })
  ])

  if (getCookies.includes('co_')) {
    // get checkout, cards and cart data
    const [checkout, dataCards, cart] = await Promise.all([
      getCheckoutV2({ requestOptions }),
      getCardsV2({ requestOptions, version: 2 }),
      getNewCart({ requestOptions })
    ])
    // validate if checkout have data
    if (checkout?.value) {
      queryClient.setQueryData('/checkoutV2', checkout)
    }
    // validate if cards have data
    if (
      isValidArrayWithData(dataCards?.cards) ||
      isValidArrayWithData(dataCards?.nominatives)
    ) {
      queryClient.setQueryData('/cards', dataCards)
    }
    // validate if cart have data
    if (cart?.address) {
      queryClient.setQueryData('/cart', cart)
    }
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    }
  }
}

export default CheckoutPage
